import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import '../assets/css/DealerDetailModal.css';
import { updateDealerDetails } from '../services/APIServices';
import { UserContext } from '../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../utils/Alerts';
import { Spinner } from './Spinner';
import MuiPhoneNumber from "material-ui-phone-number";

const DealerDetailModal = ({ open, handleClose }) => {
  const { user, company, setCompany, token } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableUpdate, setDisableUpdate] = useState(false);
  var contactNumberRegex = /^\+64[1-9]\d{6,11}$/;

  const [formData, setFormData] = useState({
    companyName: '',
    tradingName: '',
    traderNumber: '',
    logoURL: '',
    address: '',
    contactPerson: '',
    contactNumber: '',
    website: '',
    dealerTerms: ''
  });

  useEffect(() => {
    setShow(open);
    setFormData({
      ...formData,
      companyName: company?.CompanyName,
      tradingName: company?.TradingName !== undefined ? company?.TradingName : null,
      traderNumber: company?.TraderNumber,
      logoURL: company?.LogoURL !== undefined ? company?.LogoURL : null,
      address: company?.Address !== undefined ? company?.Address : null,
      contactPerson: company?.ContactPerson !== undefined ? company?.ContactPerson : null,
      contactNumber: company?.ContactNumber !== undefined ? company?.ContactNumber : null,
      website: company?.Website !== undefined ? company?.Website : null,
      dealerTerms: company?.DealerTerms !== undefined ? company?.DealerTerms : null,

    });
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePhoneChange = (value) => {
    if (value !== '' && value !== '+64' && !contactNumberRegex.test(value)) {
      setErrorMessage('Invalid contact number. Please enter valid contact number.');
      setDisableUpdate(true);
    } else {
      setErrorMessage('');
      setDisableUpdate(false);
    }
    if (value === '' || value === '+64') {
      setFormData({
        ...formData,
        contactNumber: null
      });
    } else {
      setFormData({
        ...formData,
        contactNumber: value
      });
    }
  };

  const handleUpdate = () => {
    handleClose();
    const confirmationMessage = 'Are you sure you want to update the dealer details?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          setLoaded(true);
          const response = await updateDealerDetails(formData.logoURL, user.DealerID, user.UserID, formData.tradingName, formData.address, formData.contactPerson, formData.contactNumber, formData.website, formData.dealerTerms, token);
          setLoaded(false);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully Updated Dealer details.`);
            setCompany(response.data);
          }
          handleClose();
        } catch (error) {
          setLoaded(false);
          showDefaultAlert('error', 'Error', error.response.data);
          handleClose();
        }
      },
      onCancel: async () => { },
    });
  };

  return (
    <>
      {loaded ? (<div className="middle"><Spinner></Spinner></div>) : (
        <>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle className="dialogTitle">Edit Account Details</DialogTitle>
            <DialogContent className="modalContainer">
              <TextField
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
                disabled
              />
              <TextField
                label="Trader Number"
                name="traderNumber"
                value={formData.traderNumber}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
                disabled
              />
              <TextField
                label="Trading Name"
                name="tradingName"
                value={formData.tradingName}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
              />
              <TextField
                label="Logo URL"
                name="logoURL"
                value={formData.logoURL}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
              />
              <TextField
                label="Dealer Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
              />
              <TextField
                label="Contact Person"
                name="contactPerson"
                value={formData.contactPerson}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
              />
              <MuiPhoneNumber
                variant='outlined'
                label="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handlePhoneChange}
                fullWidth
                className="inputField"
                autoFormat={false}
                countryCodeEditable={false}
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
                defaultCountry={'nz'}
                onlyCountries={['nz']}
                error={disableUpdate}
                helperText={disableUpdate ? errorMessage : ''}
              />
              <TextField
                label="Dealer Website"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
              />
              <TextField
                label="Dealer Terms"
                name="dealerTerms"
                value={formData.dealerTerms}
                onChange={handleInputChange}
                fullWidth
                className="inputField"
                InputProps={{ className: 'inputText' }}
                InputLabelProps={{ className: 'inputText' }}
              />
            </DialogContent>
            <DialogActions className="modalButtons">
              <Button onClick={() => { handleClose(); setDisableUpdate(false); }}>
                Close
              </Button>
              <Button onClick={handleUpdate} disabled={disableUpdate}>
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </>)}
    </>
  );
};

export default DealerDetailModal;


