import React, { useState, useContext } from 'react';
import { Spinner } from './Spinner';
import JSZip from 'jszip';
import { UserContext } from '../contexts/UserContext';
import swal from '@sweetalert/with-react';
import { getSignedUrlForZip } from '../services/BulkUploadAPI';
import Button from '@mui/material/Button';
import { showConfirmationAlert, showDefaultAlert } from '../utils/Alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder, faFileAlt, faImages, faFilePdf, faInbox, faEnvelope, faFileArchive, faCircle } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/BulkUpload.css';

export default function BulkUpload() {
  const [fileData, setFileData] = useState(null);
  const { token, user } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [validState, setValidState] = useState(null);

  const semiBoldStyle = { fontWeight: '600' };

  const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;

  const handleFileSelect = async (event) => {
    setFileData(event.target.files[0]);
    if (event.target.files[0]) {
      try {
        const validResponse = await validateZip(event.target.files[0]);
        setValidState(validResponse);
      } catch (error) {
        setFileData(null);
        swal({
          text: `${error.message}`,
          icon: 'error',
          dangerMode: true,
        });
      }
    }
  };

  const validateZip = async (fileData) => {
    const firstSet = new Set();
    let isAllValid = true;

    const zipFile = fileData;
    if (!zipFile) {
      alert("Please select a zip file");
      return;
    }

    const zip = await JSZip.loadAsync(zipFile);
    const zipEntries = Object.values(zip.files);
    const subfolders = zipEntries
      .map((entry) => entry.name);

    subfolders.forEach(item => {
      const splitted = item.split('/');
      firstSet.add(splitted[0]);
    });
    const firstArray = Array.from(firstSet);

    if (firstArray.length === 0) {
      throw new Error('There is no any folders');
    }

    firstArray.forEach(vin => {
      let txtFlag = false;
      let pdfFlag = false;
      let dirFlag = false;
      let invalidPdfFile = false;
      let invalidTxtFile = false;
      let invalidImgFolder = false;
      let invalidVinFolder = false;

      if (vin.length !== 17 || punctuationRegex.test(vin)) {
        invalidVinFolder = true;
      } else {
        zip.folder(vin + '/').forEach(function (relativePath, file) {
          if (!file.dir && file.name.endsWith('.pdf')) {
            pdfFlag = true;
          }
          if (!file.dir && file.name.endsWith('.txt')) {
            txtFlag = true;
          }
          if (file.dir) {
            dirFlag = true;

            zip.folder(file.name).forEach(async function (relativePath2, file2) {
              const blob = await zip.file(file.name + relativePath2).async('blob');
              var img = new Image();
              img.src = URL.createObjectURL(blob);
              img.onload = function () {
                // console.log(file2.name, ' :: Valid image');
              };
              img.onerror = function () {
                console.log(file2.name, ' :: Invalid image');
              };
            });
          }
          pdfFlag ? invalidPdfFile = false : invalidPdfFile = true;
          txtFlag ? invalidTxtFile = false : invalidTxtFile = true;
          dirFlag ? invalidImgFolder = false : invalidImgFolder = true;
        });
      }
      if (invalidVinFolder) {
        isAllValid = false;
        console.log("Ivalid Folder format: " + vin);
      }
      if (invalidPdfFile) {
        isAllValid = false;
        console.log(vin + ' does not have pdf file');
      }
      if (invalidTxtFile) {
        isAllValid = false;
        console.log(vin + ' does not have txt file');
      }
      if (invalidImgFolder) {
        isAllValid = false;
        console.log(vin + ' does not have image folder');
      }
    });

    return isAllValid;
  };

  const zipUpload = async (fileData) => {
    try {
      const zipResopnse = await getSignedUrlForZip(fileData, user.DealerID, user.UserID, token);
      console.log(zipResopnse);
      const { signedUrl } = zipResopnse.data;

      const zipUploadResult = await fetch(signedUrl, {
        method: 'PUT',
        body: fileData,
      });
      if (zipUploadResult.ok) {
        const urlObject = new URL(zipUploadResult.url);
        const zipURL = urlObject.origin + urlObject.pathname;
        return zipURL;
      } else {
        swal({
          text: 'Error uploading zip file!',
          icon: 'error',
          dangerMode: true,
        });
      }
    } catch (error) {
      swal({
        text: 'Error uploading zip file!',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const handleUpload = () => {
    const confirmationMessage = validState ? 'Are you sure, you want to upload file?' : 'Some files are not in valid format.\n Do you want to upload?';
    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          setLoaded(true);
          const url = await zipUpload(fileData);
          setLoaded(false);
          if (url) {
            showDefaultAlert('success', 'Success', 'Successfully uploaded.');
          }
        } catch (error) {
          setLoaded(false);
          showDefaultAlert('error', 'Error', error.response?.data || error.message);
        }
      },
      onCancel: async () => {
      },
    });
  };

  return (
    <>
      {loaded ? (<div className="middle" > <Spinner></Spinner></div>) : (
        <>

          <div className="Guidelines">
            <h2 style={semiBoldStyle}><FontAwesomeIcon icon={faFile} /> Guidelines</h2>
            <ul>
              <li><div style={semiBoldStyle}><FontAwesomeIcon icon={faFolder} /> Master Folder</div>
                <ul>
                  <li><FontAwesomeIcon icon={faFolder} /> <b>VIN</b>(as the name of the folder for each vehicle)
                    <ul>
                      <li><div style={semiBoldStyle}><FontAwesomeIcon icon={faFileAlt} /> Description (txt file)</div>
                        <ul>
                          <li>Price: 500$</li>
                          <li>Location: NZ</li>
                          <li>Description: all of your description as you like with additional features, finance available, your services and promotions</li>
                        </ul>
                      </li>
                      <li><FontAwesomeIcon icon={faImages} /> <b>photos/ </b>(all your photos of your vehicle)</li>
                      <li><FontAwesomeIcon icon={faFilePdf} /> <b>CIN (pdf)</b> (Customer Information Notice of your vehicle)</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <li style={{ listStyleType: 'none' }}>
              <p><div style={semiBoldStyle}><FontAwesomeIcon icon={faFileArchive} /> Zip all VIN folders in the Master Folder (Not the Master Folder itself)</div></p>
              <ul>
                <p> • Once you have repeated the above, zip the master folder, and you can upload it here as a single bundle.</p>
              </ul>
            </li>

            <li style={{ listStyleType: 'none' }}>
              <p><div style={semiBoldStyle}><FontAwesomeIcon icon={faEnvelope} /> Bulk Listing Report</div></p>
              <ul>
                <p> • Once the bulk listing is completed, you will receive an email report, and further follow-up may be required if there are any issues.</p>
              </ul>
            </li>
          </div>

          <div className="d-flex justify-content-center">
            <input type="file" accept='.zip' onChange={handleFileSelect} />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            style={{ marginTop: '10px', width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}
            disabled={fileData ? false : true}
          >
            Upload
          </Button>

        </>
      )}
    </>
  );
}
