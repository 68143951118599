import React, { useContext, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import '../assets/css/DealerServices.css';
import { showConfirmationAlert, showDefaultAlert } from '../utils/Alerts';
import { UserContext } from '../contexts/UserContext';
import { updateDealerService } from '../services/DealerServiceAPI';
import { Spinner } from './Spinner';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const costRegex = /^\d*$/;

const UpdateDealerServiceModel = ({ open, handleClose, serviceRecord, setUpdated }) => {

  const [serviceName, setServiceName] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState('');
  const [status, setStatus] = useState('');
  const [loaded, setLoaded] = useState(false);
  const { user, token } = useContext(UserContext);

  useEffect(() => {
    setServiceName(serviceRecord?.ServiceName);
    setDescription(serviceRecord?.Description);
    setCost(serviceRecord?.Cost);
    setStatus(serviceRecord?.Status);
  }, [serviceRecord]);

  const handleAddService = () => {
    handleClose();
    const confirmationMessage = 'Are you sure you want to update the dealer service?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          setLoaded(true);
          const response = await updateDealerService(user.UserID, user.DealerID, serviceName, description, cost, status, token);
          setLoaded(false);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully Updated Dealer Service.`);
            setUpdated(response.data);
          }
          handleClose();
        } catch (error) {
          setLoaded(false);
          showDefaultAlert('error', 'Error', error.response.data);
          setServiceName(serviceRecord?.ServiceName);
          setDescription(serviceRecord?.Description);
          setCost(serviceRecord?.Cost);
          setStatus(serviceRecord?.Status);
          handleClose();
        }
      },
      onCancel: async () => {
        handleClose(false);
        setServiceName(serviceRecord?.ServiceName);
        setDescription(serviceRecord?.Description);
        setCost(serviceRecord?.Cost);
        setStatus(serviceRecord?.Status);
      },
    });
    handleClose();
  };

  const handleModalClose = () => {
    handleClose(false);
    setServiceName(serviceRecord?.ServiceName);
    setDescription(serviceRecord?.Description);
    setCost(serviceRecord?.Cost);
    setStatus(serviceRecord?.Status);
  };

  return (
    <>
      {loaded ? (<div className="middle" > <Spinner></Spinner></div>) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-del-serv-modal"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '10px',
                maxHeight: '90%',
                overflowY: 'auto'
              }}
            >
              <h2 id="modal-modal-title" style={{ color: "black" }}>Update Service</h2>
              <TextField
                disabled
                label="Service Name"
                variant="outlined"
                fullWidth
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!description}
                helperText={!description ? '*Required' : ''}
                InputProps={{
                  inputComponent: TextareaAutosize,
                }}
              />
              <TextField
                label="Cost ($)"
                variant="outlined"
                fullWidth
                value={cost}
                onChange={(e) => setCost(e.target.value)}
                margin="normal"
                error={!cost || !costRegex.test(cost)}
                helperText={!cost ? '*Required' : (!costRegex.test(cost) ? 'Valid only numbers' : '')}
              />
              <FormControl variant="outlined" fullWidth margin="normal" error={!status}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Button onClick={handleModalClose} style={{ marginTop: 10, width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddService}
                  style={{ marginTop: 10, width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}
                  disabled={!description || !cost || !costRegex.test(cost) || !status}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Modal>
        </>)}
    </>
  );
};

export default UpdateDealerServiceModel;
