// src/components/NavBar.js
import React, { useState, useEffect, useContext } from 'react';
import { Grid, Toolbar, IconButton, Typography, Avatar, Menu, MenuItem } from '@mui/material';
import '../assets/css/NavBar.css';
import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { getDealerCompany } from '../services/APIServices';
import { UserContext } from '../contexts/UserContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import { Spinner } from './Spinner';
import DealerModal from './DealerDetailModal';
import NavBar from './NavBar';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';

const DealerAccount = () => {
  const { company, setCompany, user, token } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [displayCompanyDetails, setDisplayCompanyDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dealerID = user.DealerID;

      setLoaded(true);
      try {
        const res = await getDealerCompany(dealerID, token);
        setCompany(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const propertiesToDisplay = ['CompanyName', 'TraderNumber', 'TradingName', 'LogoURL', 'Address', 'ContactPerson', 'ContactNumber', 'Website', 'DealerTerms'];
    const displayFormat = { CompanyName: 'Company Name', TraderNumber: 'Trader Number', TradingName: 'Trading Name', LogoURL: 'Logo URL', Address: 'Dealer Address', ContactPerson: 'Contact Person', ContactNumber: 'Contact Number', Website: 'Dealer Website', DealerTerms: 'Dealer Terms' };
    const result = propertiesToDisplay.map(property => ({
      name: displayFormat[property],
      value: company[property] || 'N/A'
    }));
    setDisplayCompanyDetails(result);
  }, [company]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      {loaded ? (<div className="middle"><Spinner></Spinner></div>) : (
        <>
          <Grid container>
            {/* Main Content (Right) */}
            <Grid item xs={9.8} style={{ marginTop: '50px' }}> {/* Adjust the width as needed */}
              <Card sx={{ width: 1200, marginTop: 5, marginLeft: 12 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Dealer Account Details
                  </Typography>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        {displayCompanyDetails.map((detail) => (
                          <React.Fragment key={detail.name}>
                            <Grid item xs={6}>
                              <Typography variant="subtitle1">{detail.name}:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle1">{detail.value}</Typography>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </CardContent>
                    <div className="d-flex justify-content-end">
                      <IconButton onClick={handleModalOpen}>
                        <EditIcon />
                      </IconButton>
                      <DealerModal open={modalOpen} handleClose={handleModalClose} />
                    </div>
                  </Card>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default DealerAccount;
