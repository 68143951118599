import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Spinner } from './Spinner';

function Home() {
  const { token, user } = useContext(UserContext);
  return (
    <>
      {!user && !token ? (<div className="middle"><Spinner></Spinner></div>) : (
        <div></div>
      )}
    </>
  );
}

export default Home;