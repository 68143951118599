// AddDealerModel.js

import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { UserContext } from "../contexts/UserContext";
import { addDealerMember } from "../services/UserServices";
import { showDefaultAlert } from "../utils/Alerts";
import { FormControl, TextField } from "@mui/material";

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export default function AddDealerModel({ open, handleClose, setNewDealer }) {
  const { user, token } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const addDealer = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        setEmailErrorMessage("Email is required");
        return;
      }
      if (!emailRegex.test(email)) {
        setEmailErrorMessage("Invalid email address");
        return;
      }
      handleClose();
      const response = await addDealerMember(email, user.DealerID, user.UserID, token);
      setNewDealer(response.data);
      if (response.status === 200) {
        showDefaultAlert("success", "Success", `Successfully Added New User ${email}.`);
        setEmail("");
      }
    } catch (error) {
      handleClose();
      if (error.response && error.response.status === 400) {
        if (error.response.data === "This dealer member already exists.") {
          showDefaultAlert("error", "Error", "This dealer member already exists.");
        } else if (error.response.data === "This member belongs to another dealer company.") {
          showDefaultAlert("error", "Error", "This member belongs to another dealer company.");
        }
      } else {
        showDefaultAlert("error", "Error", "An error occurred. Please try again later.");
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            width: "300px",
          }}
        >
          <div className="form-group">
            <form onSubmit={addDealer}>
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailErrorMessage("");
                  }}
                />
              </FormControl>
              <p style={{ color: "red", margin: "0" }}>{emailErrorMessage}</p>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Button onClick={handleClose} style={{ width: "40%", color: 'white', backgroundColor: 'black', gap: '10%' }}>
                  Close
                </Button>
                <Button
                  className="btn"
                  style={{ width: "40%", color: 'white', backgroundColor: 'black' }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        </Box>
      </Box>
    </Modal>
  );
}
