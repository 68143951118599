import axios from 'axios';

const getuser = (email, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}user`,
    { email },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const getDealerCompany = (dealerID, token) => {
  return axios.post(`${process.env.REACT_APP_DEALER_API_GATEWAY_URL}getDealerByid`,
    { dealerID: dealerID },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const updateDealerDetails = (logoUrl, dealerID, adminID, tradingName, address, contactPerson, contactNumber, website, terms, token) => {
  return axios.post(`${process.env.REACT_APP_DEALER_API_GATEWAY_URL}update-dealeraccount`,
    { logoUrl, dealerID, adminID, tradingName, address, contactPerson, contactNumber, website, terms },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export { getuser, getDealerCompany, updateDealerDetails };