import axios from "axios";

export const getDealerPromotions = (adminID, dealerID, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}getDealerPromotions`,
    { adminID, dealerID },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const addDealerPromotion = (adminID, dealerID, promotionName, description, expiryDate, status, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}addDealerPromotion`,
    { adminID, dealerID, promotionName, description, expiryDate, status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateDealerPromotion = (adminID, dealerID, promotionName, description, expiryDate, status, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}updateDealerPromotion`,
    { adminID, dealerID, promotionName, description, expiryDate, status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};