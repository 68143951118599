import axios from "axios";

export const getDealerCompanyUsers = (dealerID, adminID, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}getDealerCompanyUsers`,
    { dealerID, adminID },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const addDealerMember = (email, dealerID, adminID, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}addDealerMember`,
    { email, dealerID, adminID },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const removeDealerUser = (userID, dealerID, adminID, operation, token) => {
  return axios.patch(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}dealerUser`,
    { userID, dealerID, adminID, operation },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateDealerUser = (userID, dealerID, adminID, email, operation, token) => {
  return axios.patch(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}dealerUser`,
    { userID, dealerID, adminID, email, operation },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}
