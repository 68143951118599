import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../assets/css/DealerServices.css';
import Button from "@mui/material/Button";
import AddDealerPromotionModel from './AddDealerPromotionModel';
import UpdateDealerPromotionModel from './UpdateDealerPromotionModel';
import { UserContext } from '../contexts/UserContext';
import { Spinner } from './Spinner';
import { useNavigate } from 'react-router-dom';
import { getDealerPromotions } from '../services/DealerPromotionAPI';
import { utcTimestampToNZTimezone } from '../utils/timeUtils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DealerPromotions() {
  const navigate = useNavigate();
  const { user, token } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [updatedRecord, setUpdatedRecord] = useState(null);

  useEffect(() => {
    const setUpdatePromotion = (updatedRecord) => {
      setPromotions((prevPromotionData) => {
        return prevPromotionData.map((promotion) => {
          if (promotion.DealerID === updatedRecord?.DealerID && promotion.PromotionName === updatedRecord?.PromotionName) {
            return updatedRecord;
          }
          return promotion;
        });
      });
    };
    setUpdatePromotion(updatedRecord);
  }, [updatedRecord]);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(true);
      try {
        const res = await getDealerPromotions(user.UserID, user.DealerID, token);
        setPromotions(res.data);
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          navigate('/signin');
        }
      } finally {
        setLoaded(false);
      }
    };
    fetchData();
  }, []);

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleOpenUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const DescriptionCell = ({ description }) => {
    const descriptionLines = (description || '').split('\n');
  
    return (
      <StyledTableCell align="left">
        <div style={{ width: '600px', whiteSpace: 'nowrap', overflowX: 'auto' }}>
          {descriptionLines.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      </StyledTableCell>
    );
  };

  return (
    <>
      {loaded ? (<div className="middle" > <Spinner></Spinner></div>) : (
        <>
          <div className="table-dealer-services d-flex justify-content-center">
            <Button
              className="btn"
              style={{ width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}
              type="submit"
              variant="contained"
              onClick={handleOpenAddModal} // Open the modal when this button is clicked
            >
              Add Promotion
            </Button>
            <AddDealerPromotionModel open={isAddModalOpen} handleClose={handleCloseAddModal} addPromotion={setPromotions} existPromotions={promotions} />
            <UpdateDealerPromotionModel open={isUpdateModalOpen} handleClose={handleCloseUpdateModal} promotionRecord={selectedPromotion} setUpdated={setUpdatedRecord} />

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Promotion Name</StyledTableCell>
                    <StyledTableCell align="left">Description</StyledTableCell>
                    <StyledTableCell align="left">Expiry date</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                {promotions.length === 0 ? (
                  <TableBody>
                    <StyledTableCell colSpan={4} align="center"><h4>No Promotions found</h4></StyledTableCell>
                  </TableBody>
                ) : (
                  <TableBody>
                    {promotions
                      .slice()
                      .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
                      .map((promotion) => (
                        <StyledTableRow key={promotion.PromotionName}>
                          <StyledTableCell component="th" scope="row" align="left">{promotion.PromotionName}</StyledTableCell>
                          <DescriptionCell description={promotion.Description} />
                          <StyledTableCell align="left">{utcTimestampToNZTimezone(promotion.ExpiryDate)}</StyledTableCell>
                          <StyledTableCell align="left">{promotion.Status}</StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              className="btn"
                              style={{ width: "40%", color: 'white', backgroundColor: 'black' }}
                              type="submit"
                              variant="contained"
                              onClick={() => { handleOpenUpdateModal(); setSelectedPromotion(promotion); }}
                            >
                              Update
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
}
