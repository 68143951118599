import React, { useEffect, useState, useContext } from "react";
import "../assets/css/DealerServices.css";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Spinner } from "./Spinner";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { TableBody } from "@mui/material";
import { showDefaultAlert } from '../utils/Alerts';
import { UserContext } from '../contexts/UserContext';
import AddDealerImportedVehicleModel from "./AddDealerImportedVehicleModel";
import { getDealerImportedVehicles } from '../services/DealerImportedVehicleAPI';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function DealerImportedVehicles() {
  const { user, token } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [importedVehicles, setImportedVehicles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(true);
      try {
        const res = await getDealerImportedVehicles(user.UserID, user.DealerID, token);
        setImportedVehicles(res.data);
      } catch (error) {
        console.log(error);
        showDefaultAlert('error', 'Error', 'Failed to retrieve the Imported Vehicle');
      } finally {
        setLoaded(false);
      }
    };
    fetchData();
  }, []);

  const interestCount = (interests) => {
    let count = 0;
    interests.forEach((element) => {
      if (element.Interest === true) {
        count++;
      }
    });
    return count;
  };

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>
      {loaded ? (
        <div className="middle">
          <Spinner></Spinner>
        </div>
      ) : (
        <>
          <div className="table-dealer-services d-flex justify-content-center">
            <Button
              className="btn"
              style={{ width: "20%", color: "white", backgroundColor: "black", marginBottom: "5px" }}
              type="submit"
              variant="contained"
              onClick={handleOpenAddModal}
            >
              Add Imported Vehicle
            </Button>
            <AddDealerImportedVehicleModel open={isAddModalOpen} handleClose={handleCloseAddModal} addImportedVehicle={setImportedVehicles} />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ImportedVehicle Chassis No</StyledTableCell>
                    <StyledTableCell align="left">Model</StyledTableCell>
                    <StyledTableCell align="left">Year</StyledTableCell>
                    <StyledTableCell align="left">Engine</StyledTableCell>
                    <StyledTableCell align="left">Transmission</StyledTableCell>
                    <StyledTableCell align="left">Grade</StyledTableCell>
                    <StyledTableCell align="left">Color</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Interests</StyledTableCell>
                  </TableRow>
                </TableHead>
                {importedVehicles.length === 0 ? (
                  <TableBody>
                    <StyledTableCell colSpan={4} align="center"><h4>No ImportedVehicles found</h4>
                    </StyledTableCell>
                  </TableBody>
                ) : (
                  <TableBody>
                    {importedVehicles
                      .slice()
                      .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
                      .map((importedVehicle) => (
                        <StyledTableRow key={importedVehicle.ChassisNo}>
                          <StyledTableCell component="th" scope="row" align="left">{importedVehicle.ChassisNo}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Model}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Year}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Engine}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Transmission}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Grade}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Color}</StyledTableCell>
                          <StyledTableCell align="left">{importedVehicle.Status}</StyledTableCell>
                          <StyledTableCell align="left">
                            {Array.isArray(importedVehicle.Interests)
                              ? interestCount(importedVehicle.Interests)
                              : 0}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
}
