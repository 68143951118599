import React, { useState, useContext } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../assets/css/DealerServices.css';
import { showConfirmationAlert, showDefaultAlert } from '../utils/Alerts';
import { UserContext } from '../contexts/UserContext';
import { Spinner } from './Spinner';
import { addDealerPromotion } from '../services/DealerPromotionAPI';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const AddDealerPromotionModel = ({ open, handleClose, addPromotion, existPromotions }) => {
  const today = new Date().toISOString().split('T')[0];
  const { user, token } = useContext(UserContext);
  const [promotionName, setPromotionName] = useState('');
  const [description, setDescription] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [status, setStatus] = useState('');
  const [loaded, setLoaded] = useState(false);

  const handleAddPromotion = () => {
    handleClose();
    const confirmationMessage = 'Are you sure you want to add new Dealer Promotion?';
    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          setLoaded(true);
          if (existPromotions && existPromotions.length !== 0) {
            const doesPromotionNameExist = existPromotions.some(promotion => promotion.PromotionName === promotionName);
            if (doesPromotionNameExist) {
              throw new Error('Promotion Already Exist');
            }
          }
          const response = await addDealerPromotion(user.UserID, user.DealerID, promotionName, description, expiryDate, status, token);
          setLoaded(false);
          if (response.status === 200) {
            addPromotion(response.data);
            showDefaultAlert('success', 'Success', 'Successfully Added Dealer Promotion.');
            setPromotionName(''); setDescription(''); setExpiryDate(''); setStatus('');
            handleClose(false);
          }
        } catch (error) {
          setLoaded(false);
          showDefaultAlert('error', 'Error', error.response?.data || error.message);
          setPromotionName(''); setDescription(''); setExpiryDate(''); setStatus('');
          handleClose(false);
        }
      },
      onCancel: async () => {
        handleClose(false);
        setPromotionName(''); setDescription(''); setExpiryDate(''); setStatus('');
      },
    });
    handleClose();
  };

  const handleModalClose = () => {
    handleClose(false);
    setPromotionName(''); setDescription(''); setExpiryDate(''); setStatus('');
  };

  return (
    <>
      {loaded ? (<div className="middle" > <Spinner></Spinner></div>) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-del-serv-modal"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '10px',
                maxHeight: '90%', 
                overflowY: 'auto'
              }}
            >
              <h2 id="modal-modal-title" style={{ color: "black" }}>Add Promotion</h2>
              <TextField
                label="Promotion Name"
                variant="outlined"
                fullWidth
                onChange={(e) => setPromotionName(e.target.value)}
                margin="normal"
                error={!promotionName}
                helperText={!promotionName ? '*Required' : ''}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!description}
                helperText={!description ? '*Required' : ''}
                InputProps={{
                  inputComponent: TextareaAutosize,
                }}
              />
              <TextField
                label="Expiry Date"
                variant="outlined"
                fullWidth
                type="date"  // Use type="date" for date input
                onChange={(e) => setExpiryDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                error={!expiryDate}
                helperText={!expiryDate ? '*Required' : ''}
                InputProps={{
                  inputProps: { min: today }, // Disable dates before today
                }}
              />
              <FormControl variant="outlined" fullWidth margin="normal" error={!status}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Button onClick={handleModalClose} style={{ marginTop: 10, width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddPromotion}
                  style={{ marginTop: 10, width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}
                  disabled={!promotionName || !description || !expiryDate || !status}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddDealerPromotionModel;