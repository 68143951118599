import Swal from "sweetalert2";

export const showDefaultAlert = (errorIcon, errorTitle, errorMessage) => {
  Swal.fire({
    icon: errorIcon,
    title: errorTitle,
    text: errorMessage,
  });
};

export const showConfirmationAlert = ({ confirmationMessage, onConfirm, onCancel }) => {
  Swal.fire({
    icon: 'warning',
    title: 'Confirmation',
    text: confirmationMessage,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel();
    }
  });
};