const awsConfig = {
    Auth: {
        identityPoolId: `${process.env.REACT_APP_DEALER_COGNITO_IDENTITY_POOL_ID}`,
        region: `${process.env.REACT_APP_DEALER_COGNITO_REGION}`,
        userPoolId: `${process.env.REACT_APP_DEALER_COGNITO_USER_POOL_ID}`,
        userPoolWebClientId: `${process.env.REACT_APP_DEALER_COGNITO_USER_POOL_WEB_CLIENT_ID}`,
        oauth: {
            domain: `${process.env.REACT_APP_DEALER_COGNITO_USER_POOL_DOMAIN_URL}`,
            scope: ['email', 'openid'],
            redirectSignIn: `${process.env.REACT_APP_DEALER_DOMAIN_URL}/signin`,
            redirectSignOut: `${process.env.REACT_APP_DEALER_DOMAIN_URL}/signin`,
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
};

export default awsConfig;