import axios from "axios";

export const getDealerImportedVehicles = (adminID, dealerID, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}getImportedVehicleDetails`,
    { adminID, dealerID, },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const addDealerImportedVehicle = (adminID, dealerID, chassisNo, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}addImportedVehicleDetails`,
    { adminID, dealerID, chassisNo },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};