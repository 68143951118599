import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import AddDealerServiceModel from './AddDealerServiceModel';
import UpdateDealerServiceModal from './UpdateDealerServiceModal';
import { UserContext } from '../contexts/UserContext';
import { getDealerServices } from '../services/DealerServiceAPI';
import { Spinner } from './Spinner';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DescriptionCell = ({ description }) => {
  const descriptionLines = (description || '').split('\n');

  return (
    <StyledTableCell align="left">
      <div style={{ width: '600px', whiteSpace: 'nowrap', overflowX: 'auto' }}>
        {descriptionLines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </StyledTableCell>
  );
};

export default function DealerServices() {
  const navigate = useNavigate();
  const { user, token } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [updatedRecord, setUpdatedRecord] = useState(null);

  useEffect(() => {
    const setUpdateService = (updatedRecord) => {
      setServices((prevServiceData) => {
        return prevServiceData.map((service) => {
          if (service.DealerID === updatedRecord?.DealerID && service.ServiceName === updatedRecord?.ServiceName) {
            return updatedRecord;
          }
          return service;
        });
      });
    };
    setUpdateService(updatedRecord);
  }, [updatedRecord]);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(true);
      try {
        const res = await getDealerServices(user.UserID, user.DealerID, token);
        setServices(res.data);
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          navigate('/signin');
        }
      } finally {
        setLoaded(false);
      }
    };
    fetchData();
  }, []);

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleOpenUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  return (
    <>
      {loaded ? (<div className="middle" > <Spinner></Spinner></div>) : (
        <>
          <div className="table-dealer-services d-flex justify-content-center">
            <Button
              className="btn"
              style={{ width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}
              variant="contained"
              onClick={handleOpenAddModal}
            >
              Add Service
            </Button>
            <AddDealerServiceModel open={isAddModalOpen} handleClose={handleCloseAddModal} addService={setServices} existServices={services} />

            <UpdateDealerServiceModal open={isUpdateModalOpen} handleClose={handleCloseUpdateModal} serviceRecord={selectedService} setUpdated={setUpdatedRecord} />

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Service Name</StyledTableCell>
                    <StyledTableCell align="left">Description</StyledTableCell>
                    <StyledTableCell align="left">Cost</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                {services.length === 0 ? (
                  <TableBody>
                    <StyledTableCell colSpan={4} align="center"><h4>No Services found</h4></StyledTableCell>
                  </TableBody>
                ) : (
                  <TableBody>
                    {services
                      .slice()
                      .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
                      .map((service) => (
                        <StyledTableRow key={service.ServiceName}>
                          <StyledTableCell component="th" scope="row">{service.ServiceName}</StyledTableCell>
                          <DescriptionCell description={service.Description} />
                          <StyledTableCell align="left">${service.Cost}</StyledTableCell>
                          <StyledTableCell align="left">{service.Status}</StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              className="btn"
                              style={{ width: "40%", color: 'white', backgroundColor: 'black' }}
                              variant="contained"
                              onClick={() => { handleOpenUpdateModal(); setSelectedService(service); }} // Open the update modal when this button is clicked
                            >
                              Update
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </>)}
    </>
  );
}
