// export default NavBar;

import React, { useState, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { UserContext } from '../contexts/UserContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import { ExpandLess, ExpandMore, Collapse, } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { navigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RvHookupIcon from "@mui/icons-material/RvHookup";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { blueGrey } from '@mui/material/colors';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { clearDetailsLocalStorage } from '../utils/LocalStorageUtils';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

async function signOut() {
  try {
    clearDetailsLocalStorage();
    await Auth.signOut();
  } catch (error) {
    console.error('error', 'Error', 'An error occurred. Please try again later.');
  }
}

export default function NavBar({ setDrawerOpen }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openExpand, setOpenExpand] = React.useState(false);
  const navigate = useNavigate();

  const { user, token, company } = useContext(UserContext);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);

  const handleClick = () => {
    setOpenExpand(!openExpand); // Toggle the state when the menu icon is clicked
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const languageStyle = {
    fontSize: '14px', // Adjust the font size as needed
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setDrawerOpen(true); // Pass the state to the parent component
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDrawerOpen(false); // Pass the state to the parent component
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ 'backgroundColor': '#FFFFFF' }}>
        <Toolbar>
          <IconButton
            color="#000000"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap component="div">
            <div className='welcome' style={{ 'color': '#000000' }}>{company ? company.CompanyName : ""}</div>
          </Typography>

          {/* <IconButton
            color="inherit"
            onClick={handleLanguageMenuOpen}
            aria-controls="language-menu"
            aria-haspopup="true"
            style={{
              borderRadius: '0',
              display: 'flex',
              // flexDirection: 'column',
              alignItems: 'center',
              ...languageStyle,
              color: '#000000',
            }}
          >
            Language <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id="language-menu"
            anchorEl={languageAnchorEl}
            keepMounted
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageMenuClose}
          >
            <MenuItem onClick={handleLanguageMenuClose}>English</MenuItem>            
          </Menu>

          <IconButton color="#000000">
            <SearchIcon />
          </IconButton>
          <IconButton color="#000000">
            <NotificationsIcon />
          </IconButton> */}

          {/* <IconButton color="inherit">
            <Avatar alt="User Avatar" src="/path/to/avatar-image.jpg" />
          </IconButton> */}

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Dealer Account" />
            {openExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={openExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => navigate('/dealer-account')}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Account Details" />
              </ListItemButton>
              <ListItemButton onClick={() => navigate('/users')}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Dealer Agents" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        <List>
          <ListItemButton onClick={() => navigate('/dealer-services')}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Dealer Services" />
          </ListItemButton>
        </List>
        <List>
          <ListItemButton onClick={() => navigate('/dealer-promotions')}>
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Dealer Promotions" />
          </ListItemButton>
        </List>
        <List>
          <ListItemButton onClick={() => navigate("/dealer-importedVehicles")}>
            <ListItemIcon>
              <RvHookupIcon />
            </ListItemIcon>
            <ListItemText primary="Imported Vehicles" />
          </ListItemButton>
        </List>
        <List>
          <ListItemButton onClick={() => navigate('/bulk-upload')}>
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Bulk Upload" />
          </ListItemButton>
        </List>

        <div style={{ position: 'absolute', bottom: '0', left: '0', right: '0' }}>
          <List>
            <ListItemButton onClick={() => signOut()}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </List>
        </div>
      </Drawer>
    </Box>
  );
}