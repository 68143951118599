import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <NavBar setDrawerOpen={setDrawerOpen} />
      <div style={{ marginLeft: '200px' }}>
        <Outlet />
        <Footer drawerOpen={drawerOpen} />
      </div>
    </>
  );

};

export default Layout;