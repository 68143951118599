import axios from "axios";

export const getDealerServices = (adminID, dealerID, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}getDealerServices`,
    { adminID, dealerID },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const addDealerService = (adminID, dealerID, serviceName, description, cost, status, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}addDealerService`,
    { adminID, dealerID, serviceName, description, cost, status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateDealerService = (adminID, dealerID, serviceName, description, cost, status, token) => {
  return axios.post(
    `${process.env.REACT_APP_DEALER_API_GATEWAY_URL}updateDealerService`,
    { adminID, dealerID, serviceName, description, cost, status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};