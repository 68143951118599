// src/App.js
import React, { useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from './aws-config';
import { UserProvider } from './contexts/UserContext';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SingleSignOn from './components/SingleSignOn';
import Users from './components/Users';
import DealerAccount from './components/DealerAccount';
import DealerServices from './components/DealerServices';
import DealerPromotions from './components/DealerPromotions';
import Layout from './components/Layout';
import Home from './components/Home';
import DealerImportedVehicles from "./components/DealerImportedVehicles";
import BulkUpload from './components/BulkUpload';

Amplify.configure(awsConfig);

function App() {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          // Fetch the current authenticated user's attributes
          const user = await Auth.currentAuthenticatedUser();
          // Get the email attribute from the user object
          setEmail(user.signInUserSession.idToken.payload.email);
          setToken(user.signInUserSession.idToken.jwtToken);
        }
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
    getUserAttributes();
  }, []);

  return (
    <Router>
      <UserProvider value={{ token, setToken, email, setEmail }}>
        <Routes>
          <Route path="/signin" element={<SingleSignOn />} />
          <Route element={<Layout />}>
            <Route path="/" element={token ? <Home /> : <Navigate to="/signin" />} />
            <Route path='/users' element={token ? <Users /> : <Navigate to="/signin" />} />
            <Route path="/dealer-account" element={token ? <DealerAccount /> : <Navigate to="/signin" />} />
            <Route path="/dealer-services" element={token ? <DealerServices /> : <Navigate to="/signin" />} />
            <Route path="/dealer-promotions" element={token ? <DealerPromotions /> : <Navigate to="/signin" />} />
            <Route path="/dealer-importedVehicles" element={token ? <DealerImportedVehicles /> : <Navigate to="/signin" />} />
            <Route path="/bulk-upload" element={token ? <BulkUpload /> : <Navigate to="/signin" />} />
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
