import dayjs from 'dayjs';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';

// Extend dayjs with the UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

function utcTimestampToNZTimezone(utcTimestamp) {
  const nztimezone = 'Pacific/Auckland';
  const utcDatetime = new Date(utcTimestamp * 1000);
  const formattedDate = dayjs(utcDatetime).tz(nztimezone).format('YYYY-MM-DD');
  return formattedDate;
}

export { utcTimestampToNZTimezone };