import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { UserContext } from "../contexts/UserContext";
import { updateDealerUser } from "../services/UserServices";
import { showConfirmationAlert, showDefaultAlert } from "../utils/Alerts";
import { FormControl, TextField } from "@mui/material";
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const UpdateDealerUserModel = ({ open, handleUpdateClose, dealerUser, setUpdatedRecord }) => {

  const { user, token } = useContext(UserContext);
  const [email, setEmail] = useState(dealerUser.Email || '');
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      setEmailErrorMessage("Invalid email address");
      return;
    }
    handleUpdateClose();
    const operation = "update";
    const confirmationMessage = 'Are you sure you want to update this user?';
    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await updateDealerUser(dealerUser.UserID, user.DealerID, user.UserID, email, operation, token);
          if (response.status === 200) {
            showDefaultAlert("success", "Success", `Successfully updated ${email}.`);
            setEmail("");
            setUpdatedRecord(response.data);
          }
        } catch (error) {
          handleUpdateClose();
          if (error.response && error.response.status === 400) {
            if (error.response.data === "Dealer company not exists") {
              showDefaultAlert('error', 'Error', "Dealer company not exists");
            } else if (error.response.data === "User does not exist") {
              showDefaultAlert('error', 'Error', 'User does not exist');
            } else if (error.response.data === "User is not valid user") {
              showDefaultAlert('error', 'Error', 'User is not active dealer member.');
            } else if (error.response.data === "Email already exists") {
              showDefaultAlert('error', 'Error', 'Email already exists');
            }
          } else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
        }
      },
      onCancel: () => {
        handleUpdateClose();
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleUpdateClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            width: "300px",
          }}
        >
          <div className="form-group">
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <TextField
                  style={{ marginBottom: "16px" }}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailErrorMessage("");
                  }}
                />
                <p style={{ color: "red", margin: "0" }}>{emailErrorMessage}</p>
                {dealerUser.Nickname ? (
                  <TextField
                    style={{ marginBottom: "16px" }}
                    label="Nickname"
                    variant="outlined"
                    fullWidth
                    required
                    value={dealerUser.Nickname}
                    disabled
                  />
                ) : null}
                <TextField
                  style={{ marginBottom: "16px" }}
                  label="Roles"
                  variant="outlined"
                  fullWidth
                  required
                  value={dealerUser.Roles.join(', ') || ''}
                  disabled
                />
              </FormControl>

              <p style={{ color: "red", margin: "0" }}></p>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Button onClick={handleUpdateClose} style={{ width: "40%", color: 'white', backgroundColor: 'black', gap: '10%' }}>
                  Close
                </Button>
                <Button
                  className="btn"
                  style={{ width: "40%", color: 'white', backgroundColor: 'black' }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateDealerUserModel;