import React, { useContext, useState } from "react";
import { Spinner } from "./Spinner";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { showConfirmationAlert, showDefaultAlert } from '../utils/Alerts';
import { UserContext } from "../contexts/UserContext";
import { addDealerImportedVehicle } from "../services/DealerImportedVehicleAPI";

const AddDealerImportedVehicleModel = ({ open, handleClose, addImportedVehicle }) => {
  const { user, token } = useContext(UserContext);
  const [importedVehicleChassisNo, setImportedVehicleChassisNo] = useState("");
  const [loaded, setLoaded] = useState(false);

  const handleAddImportedVehicle = () => {
    handleClose();
    const confirmationMessage = 'Are you sure you want to add new Dealer Imported Vehicle?';
    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          setLoaded(true);
          const response = await addDealerImportedVehicle(user.UserID, user.DealerID, importedVehicleChassisNo, token);
          setLoaded(false);
          if (response.status === 200) {
            addImportedVehicle(prevImportedVehicles => [...prevImportedVehicles, response.data]);
            showDefaultAlert('success', 'Success', 'Successfully Added Vehicle.');
            setImportedVehicleChassisNo('');
            handleClose(false);
          }
        } catch (error) {
          setLoaded(false);
          if (error.response.data.error === 'No Imported Vehicle Found') {
            showDefaultAlert('info', 'Info', 'No Imported Vehicle Found');
          } else {
            showDefaultAlert('error', 'Error', error.response?.data || error.message);
          }
          setImportedVehicleChassisNo('');
          handleClose(false);
        }
      },
      onCancel: async () => {
        handleClose(false);
        setImportedVehicleChassisNo('');
      },
    });
    handleClose();
  };

  const handleModalClose = () => {
    handleClose(false);
    setImportedVehicleChassisNo("");
  };

  return (
    <>
      {loaded ? (
        <div className="middle">
          {" "}
          <Spinner></Spinner>
        </div>
      ) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-del-serv-modal"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px",
                maxHeight: "90%",
                overflowY: "auto",
              }}
            >
              <h2 id="modal-modal-title" style={{ color: "black" }}>
                Add Imported Vehicle
              </h2>
              <TextField
                label="Imported Vehicles Chassis No"
                variant="outlined"
                fullWidth
                onChange={(e) => setImportedVehicleChassisNo(e.target.value)}
                margin="normal"
                error={!importedVehicleChassisNo}
                helperText={!importedVehicleChassisNo ? "*Required" : ""}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Button
                  onClick={handleModalClose}
                  style={{
                    marginTop: 10,
                    width: "20%",
                    color: "white",
                    backgroundColor: "black",
                    marginBottom: "5px",
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddImportedVehicle}
                  style={{
                    marginTop: 10,
                    width: "20%",
                    color: "white",
                    backgroundColor: "black",
                    marginBottom: "5px",
                  }}
                  disabled={!importedVehicleChassisNo}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddDealerImportedVehicleModel;
