import React, { useContext, useEffect, useState } from 'react';
import { getDealerCompanyUsers, removeDealerUser } from '../services/UserServices';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, Notifications as NotificationsIcon, Search as SearchIcon } from '@mui/icons-material';
import { UserContext } from '../contexts/UserContext';
import { Spinner } from './Spinner';
import AddDealerModel from './AddDealerModel';
import NavBar from './NavBar';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { showConfirmationAlert, showDefaultAlert } from '../utils/Alerts';
import UpdateDealerUserModel from './UpdateDealerUserModel';

const Users = () => {
  const { user, token, company } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [update, setupdate] = useState(false);
  const [dealerUser, setdealerUser] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleupdateOpen = (dealerUser) => {
    setupdate(true);
    setdealerUser(dealerUser);
  };

  const handleUpdateClose = () => setupdate(false);

  const [userData, setUserData] = useState([]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const setNewDealer = (newDealer) => {
    setUserData(prevData => {
      const index = prevData.findIndex(item => item.UserID === newDealer.UserID);
      if (index !== -1) {
        const updatedItems = [...prevData];
        updatedItems[index] = newDealer;
        return updatedItems;
      } else {
        return [...prevData, newDealer];
      }
    });
  };

  const setUpdatedRecord = (updatedRecord) => {
    const updatedUserData = userData.map((user) => {
      if (user.UserID === updatedRecord.UserID) {
        return updatedRecord;
      }
      return user;
    });
    setUserData(updatedUserData);
  };


  const handleDelete = async (userID) => {
    const dealerID = user.DealerID;
    const adminID = user.UserID;
    const operation = 'remove';
    const confirmationMessage = 'Are you sure you want to delete the dealer member?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await removeDealerUser(userID, dealerID, adminID, operation, token);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully deleted the dealer member.`);
            setUserData((prevUserData) => prevUserData.filter((user) => user.UserID !== userID));
          }
        } catch (error) {
          console.log(error.response);
          setLoaded(false);
          if (error.response && error.response.status === 400) {
            if (error.response.data === "Dealer company not exists") {
              showDefaultAlert('error', 'Error', "Dealer company not exists");
            } else if (error.response.data === "User does not exist") {
              showDefaultAlert('error', 'Error', 'User does not exist');
            } else if (error.response.data === "User is not valid user") {
              showDefaultAlert('error', 'Error', 'User is not active dealer member.');
            } else (showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.'));
          } else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
        }
      }
    });
  };

  useEffect(() => {
    const dealerID = user.DealerID;
    const adminID = user.UserID;

    setLoaded(true);
    const fetchUsers = async () => {
      try {
        const response = await getDealerCompanyUsers(dealerID, adminID, token);
        setUserData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data === "This dealer member already exists.") {
            showDefaultAlert('error', 'Error', "This dealer member already exists.");
          } else if (error.response.data === "This member belongs to another dealer company.'") {
            showDefaultAlert('error', 'Error', 'This member belongs to another dealer company.');
          }
        } else {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      } finally {
        setLoaded(false);
      }
    };
    fetchUsers();
  }, [user.DealerID, user.UserID, token]);

  return (
    <>
      {loaded ? (
        <div className="middle">
          <Spinner></Spinner>
        </div>
      ) : (
        <Grid container>
          {/* Main Content (Right) */}
          <Grid item xs={9.8}>
            {/* <NavBar /> */}
            <div>
              <div style={{ marginTop: '140px', marginLeft: '120px' }}>
                <div>
                  {open && <AddDealerModel open={open} handleClose={handleClose} setNewDealer={setNewDealer} />}
                </div>
                <div>
                  {update && <UpdateDealerUserModel open={update} handleUpdateClose={handleUpdateClose} setUpdatedRecord={setUpdatedRecord} dealerUser={dealerUser} />}
                </div>
                <Button
                  className="btn"
                  style={{ width: "20%", color: 'white', backgroundColor: 'black', marginBottom: '5px' }}
                  type="submit"
                  variant="contained"// Open the modal when this button is clicked
                  onClick={handleOpen}
                >
                  Add Dealer
                </Button>
              </div>
              <TableContainer component={Paper} style={{ marginLeft: '120px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{ width: '16%' }}>Nickname</StyledTableCell>
                        <StyledTableCell style={{ width: '16%' }}>Email</StyledTableCell>
                        <StyledTableCell style={{ width: '16%' }}>Status</StyledTableCell>
                        <StyledTableCell style={{ width: '16%' }}>Roles</StyledTableCell>
                        <StyledTableCell style={{ width: '16%' }}>Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.map((user) => (
                        <StyledTableRow key={user.id}>
                          <TableCell>{user.Nickname}</TableCell>
                          <TableCell>{user.Email}</TableCell>
                          <TableCell>{user.Status}</TableCell>
                          <TableCell>{user.Roles.join(", ")}</TableCell>
                          <TableCell>
                            <DeleteIcon onClick={() => handleDelete(user.UserID)} />
                            <EditIcon onClick={() => handleupdateOpen(user)} />
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Users;