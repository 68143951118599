import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/SingleSignOn.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import google from '../assets/img/icon _google.png';
import fb from '../assets/img/icon _fb.png';
import amazon from '../assets/img/icon _amazon.png';
import apple from '../assets/img/icon _apple.png';
import waka_logo from '../assets/img/waka-logo.png';
import swal from '@sweetalert/with-react';
import { Spinner } from './Spinner';
import { getuser } from '../services/APIServices';
import { UserContext } from '../contexts/UserContext';
import { clearDetailsLocalStorage } from '../utils/LocalStorageUtils';

export default function SingleSignOn() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const { setUser, setToken } = useContext(UserContext);

  useEffect(() => {
    const getUserAttributes = async () => {
      setLoaded(true);
      try {
        const user = await Auth.currentAuthenticatedUser();

        //Fetch User
        try {
          const token = user.signInUserSession.idToken.jwtToken;
          const validUser = await getuser(user.signInUserSession.idToken.payload.email, token);
          setUser(validUser.data);
          setToken(token);
          navigate('/');
        } catch (error) {
          swal({
            text: error.response.data,
            icon: 'error',
            dangerMode: true,
          }).then(async () => {
            clearDetailsLocalStorage();
            try {
              await Auth.signOut();
            } catch (error) {
              swal({
                text: 'Failed to sign out',
                icon: 'error',
                dangerMode: true,
              });
            }
          });
        }

      } catch (error) {
        console.error('Failed to retrieve user', error);
      } finally {
        setLoaded(false);
      }
    };
    getUserAttributes();
  }, []);

  const handleSignIn = async (provider) => {
    try {
      await Auth.federatedSignIn(provider);
    } catch (error) {
      console.error('Error signing in with federated provider:', error);
    }
  };

  return (
    <>
      {loaded ? (<div className="middle"><Spinner></Spinner></div>) : (
        <div className="background">
          <div className="inner">
            <div className="waka_logo">
              <img src={waka_logo} alt="Waka Logo" />
            </div>
            <div className="">
              <label className="custom-control-label">Sign in to Waka Online Dealer App</label><br />
            </div>
            <div className="social-buttons mt-2">
              {process.env.REACT_APP_ENABLE_GOOGLE_SSO === 'true' && <button
                onClick={() => { handleSignIn({ provider: 'Google' }); }}
                className="SSO-Google btn-lg btn-block"
              >
                <img src={google} alt="Google Logo" />
                <div className="button-text">
                  Continue with Google
                </div>
              </button>}
              {process.env.REACT_APP_ENABLE_FACEBOOK_SSO === 'true' && <button
                onClick={() => { handleSignIn({ provider: 'Facebook' }); }}
                className="SSO-Facebook btn-lg btn-block"
              >
                <img src={fb} alt="fb Logo" />
                <div className="button-text">
                  Continue with Facebook
                </div>
              </button>}
              {process.env.REACT_APP_ENABLE_AMAZON_SSO === 'true' && <button
                onClick={() => { handleSignIn({ provider: 'LoginWithAmazon' }); }}
                className="SSO-Amazon btn-lg btn-block"
              >
                <img src={amazon} alt="amazon Logo" />
                <div className="button-text">
                  Continue with Amazon
                </div>
              </button>}
              {process.env.REACT_APP_ENABLE_APPLE_SSO === 'true' && <button
                onClick={() => { handleSignIn({ provider: 'SignInWithApple' }); }}
                className="SSO-Apple btn-lg btn-block"
              >
                <img src={apple} alt="apple Logo" />
                <div className="button-text">
                  Continue with Apple
                </div>
              </button>}
            </div>
          </div>
        </div>
      )}
    </>
  );
}


