import { createContext, useState, useEffect } from 'react';
import { getDealerCompany } from '../services/APIServices';

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {});
    const [company, setCompany] = useState(localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : {});
    const [token, setToken] = useState(localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : {});
    useEffect(() => {
        const fetchData = async () => {
            const dealerID = user.DealerID;
            try {
                const res = await getDealerCompany(dealerID, token);
                setCompany(res.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        if (user) {
            fetchData();
        }
    }, [user, token]);
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    useEffect(() => {
        localStorage.setItem('company', JSON.stringify(company));
    }, [company]);

    useEffect(() => {
        localStorage.setItem('token', JSON.stringify(token));
    }, [token]);
    // Render the provider component with the user ID value and setter function in the context
    return (
        <UserContext.Provider value={{ user, setUser, company, setCompany, token, setToken }}>
            {props.children}
        </UserContext.Provider>
    );
};