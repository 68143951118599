import React from 'react';
import '../assets/css/Footer.css';

function Footer({ drawerOpen }) {
  const footerStyle = {
    marginLeft: drawerOpen ? '240px' : '72px',
    transition: 'margin-left 0.3s',
    width: drawerOpen ? 'calc(100% - 240px)' : 'calc(100% - 72px)',
  };

  return (
    <div className="footer" style={footerStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <p className='footer-paragraph'>
              <span><a href="https://wakaonline.nz/privacyPolicy" className='footer-pages' target="_blank" rel="noopener noreferrer">Privacy Policy | </a></span>
              <span><a href="https://dev.wakaonline.nz/termsConditions" className='footer-pages' target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>
            </p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <p className='footer-paragraph footer-copyright'>© 2023 Copyright: Waka Online Limited | 100% Owned by Kiwis</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
